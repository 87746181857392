export async function IsRegionSupported() {
    try {
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        const userRegion = data.country_code;

        return userRegion !== 'US' && userRegion !== 'CA';

    } catch (error) {
        console.error('Error fetching location:', error);
    };
    return false;
};