import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';

export default function FastPerformance() {

  return (
    <Container
      id="edge-handling"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
      }}
    >
      <Grid container spacing={2} alignItems="center" direction={{ xs: 'column', md: 'row' }}>
        <Grid size={{ xs: 12, md: 8 }}>
          <video loop muted autoPlay playsInline preload="auto" style={{ width: "100%", height: "100%", borderRadius: "8px", padding: "16px" }}>
            <source src="./assets/comparison.webm" type="video/webm" />
            Your browser does not support the video tag.
          </video>

        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          <Box sx={{ textAlign: 'left' }}>
            <Typography
              component="h2"
              variant="h4"
              gutterBottom
              sx={{ color: 'text.primary' }}
            >
              Fast performance
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
              MagicDefocus2 is designed for high performance and GPU acceleration. Even at high quality, it's much faster than the original MagicDefocus. This means you can focus on refining your art and iterating on ideas, without waiting for defocus to slow you down. MagicDefocus2 is optimized to let your creativity shine, not get bogged down in processing time.

              <br></br><br></br>
              <span style={{ fontStyle: 'italic', color: 'text.secondary' }}>
                * This is of course depending on whether you are using a GPU. Which is absolutely recommended. The defocus mode; as deep is heavier to process for example. Or the resolution, as rendering higher resolutions cost more processing power.
              </span>
            </Typography>

          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
