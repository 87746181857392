import * as React from 'react';
import { useState } from 'react';

import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Button, Modal, Box, TextField, FormControlLabel, Checkbox, Link } from '@mui/material';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  height: 400,
  rounded: 10,
  borderRadius: 1,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
};


async function RequestTrial(name, email) {
  const url = "https://api.vinkvfx.com/v1/magicdefocus2/request-trial";
  const body = JSON.stringify({
    name: name,
    email: email
  });

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: body
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.text();
    return data;
  } catch (error) {
    console.error('Error fetching checkout URL:', error);
    throw error;
  }
}

const TrialForm = ({ isSupported }) => {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [emailError, setEmailError] = useState(false);
  const [agrees, setIsChecked] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleContinue = () => {
    if (name && validateEmail(email) && agrees) {
      setIsButtonDisabled(true);

      RequestTrial(name, email)
        .then(url => {
          const cleanUrl = url.replace(/^"|"$/g, '');
          window.location.href = cleanUrl;
        })
        .catch(error => {
          console.error('Error during trial request:', error);
        })
        .finally(() => {
          setIsButtonDisabled(false);
        });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !isButtonDisabled) {
      handleContinue();
    }
  };

  const handleInputChange = () => {
    const isValidEmail = validateEmail(email);
    setEmailError(email.length > 3 && !isValidEmail);
    const isValid = name.length > 0 && email.length > 3 && isValidEmail && agrees;
    setIsButtonDisabled(!isValid);
  };

  const handleCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setIsChecked(isChecked);

    const isValidEmail = validateEmail(email);
    const isValid = name.length > 0 && email.length > 3 && isValidEmail && isChecked;
    setIsButtonDisabled(!isValid);
  };
  return (
    <div>
      <Button
        variant='contained'
        color='secondary'
        disabled={!isSupported}
        size='small'
        onClick={handleOpen}
      >
        Try it out
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style} textAlign="center">
          <h2 id="modal-title">Enter your details</h2>
          <TextField
            label="Name"
            variant="standard"
            fullWidth
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              handleInputChange();
            }}
            onKeyDown={handleKeyDown}
            autoFocus
            slotProps={{
              inputLabel: {
                style: { marginTop: '-6.5px' },
              },
            }}
          />
          <TextField
            label="Email"
            variant="standard"
            fullWidth
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              handleInputChange();
            }}
            onKeyDown={handleKeyDown}
            sx={{ marginTop: "20px" }}
            error={emailError}
            helperText={emailError ? "Invalid email address" : ""}
            slotProps={{
              inputLabel: {
                style: { marginTop: '-6.5px' },
              },
            }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={agrees}
                onChange={handleCheckboxChange}
                color="secondary"
              />
            }
            sx={{ padding: "20px" }}
            label={
              <Typography>
                I have read and agree to the{' '}
                <Link href="./legal/EULA.pdf" target="_blank" rel="noopener noreferrer">
                  End-User License Agreement
                </Link>{' '}
                and the{' '}
                <Link href="./legal/Privacy_Policy.pdf" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </Link>.
              </Typography>
            } />
          <Button
            variant="contained"
            color="secondary"
            onClick={handleContinue}
            disabled={isButtonDisabled}
            sx={{ mt: 2, margin: "0" }}
          >
            Get your free 14 day trial
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default function Trial({ isSupported }) {
  return (
    <Container
      id="trial"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
        maxWidth: '100%',
      }}
    >
      <Box
        sx={{
          width: { xs: '90%', sm: '100%', md: '60%' },
          textAlign: { xs: 'center', sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Experience the difference. Try it yourself.
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          MagicDefocus2 offers a 14 day no-strings-attached trial to experience it yourself.
          <br /><br />
        </Typography>

        <TrialForm isSupported={isSupported} />

      </Box>
    </Container>

  );
}
