const price_ids = {
    nodelocked_annually: "pri_01jd9qvht86qdwxc53qwpq3sb0",
    nodelocked_monthly: "pri_01jd9qrykn6sx0j2an7nhbs355",
    floating_annually: "pri_01jd9qxbehkym8zqq6hvnn4acd",
    floating_monthly: "pri_01jd9qsv4x95xtap70xs1mwbp8",
    site_annually: "pri_01jd9qyxg6gj1th49c13bgfxsm",
    site_monthly: "pri_01jd9qy1fbf6j3063322y0vq6y",
}

export function Checkout(paddle, product, is_monthly) {
    const price_name = is_monthly ? product + "_monthly" : product + "_annually";
    const price_id = price_ids[price_name];
    paddle.Checkout.open({
        items: [{ priceId: price_id, quantity: 1 }],
    });
}