import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { RssFeed } from '@mui/icons-material';
import MagicDefocusIcon from './MagicDefocusIcon';
function Copyright() {
  return (
    <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
      {'Copyright © '}
      <Link color="text.secondary" href="https://vinkvfx.com/">
        Vink VFX
      </Link>
      &nbsp;
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  const handleClick = () => {
    window.open('https://newsletter.magicdefocus.com', '_blank');
  };
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
            <MagicDefocusIcon />
            <Typography variant="body2" gutterBottom sx={{ fontWeight: 600, mt: 2 }}>
              Join the newsletter
            </Typography>


            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 2 }}>
              Subscribe to receive updates and be the first to know about new releases!
            </Typography>
            <Button type="submit" variant="contained" color="primary" size="small" onClick={handleClick}>
              Subscribe
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            MagicDefocus2
          </Typography>
          <Link color="text.secondary" variant="body2" href="#intro">
            Features
          </Link>
          <Link color="text.secondary" variant="body2" href="#pricing">
            Pricing
          </Link>
          <Link color="text.secondary" variant="body2" href="#faq">
            FAQs
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            Company
          </Typography>
          <Link color="text.secondary" variant="body2" href="https://vinkvfx.com" target="_blank" rel="noopener noreferrer">
            About
          </Link>
          <Link color="text.secondary" variant="body2" href="https://support.vinkvfx.com" target="_blank" rel="noopener noreferrer">
            Support
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            Legal
          </Typography>
          <Link color="text.secondary" variant="body2" href="./legal/EULA.pdf" target="_blank" rel="noopener noreferrer">
            EULA
          </Link>
          <Link color="text.secondary" variant="body2" href="./legal/Privacy_Policy.pdf" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </Link>
          <Link color="text.secondary" variant="body2" href="https://support.vinkvfx.com" target="_blank" rel="noopener noreferrer">
            Contact
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          <Link color="text.secondary" variant="body2" href="./legal/Privacy_Policy.pdf" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </Link>
          <Typography sx={{ display: 'inline', mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" variant="body2" href="./legal/EULA.pdf" target="_blank" rel="noopener noreferrer">
            End-user license agreement
          </Link>
          <Typography sx={{ display: 'inline', mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" variant="body2" href="./legal/Refund_Policy.pdf" target="_blank" rel="noopener noreferrer">
            Refund Policy
          </Link>
          <Copyright />
          <br></br>
          <Typography sx={{ display: 'inline', opacity: 0.5 }}>NUKE is a trademark of The Foundry Visionmongers Ltd.

            Linux® is a registered trademark of Linus Torvalds.

            Windows® and DirectX® are a registered trademark of Microsoft Corporation.

            macOS®, Metal®, MacBook®, and Apple® are trademarks of Apple Inc., registered in the U.S. and other countries.

            Vulkan® and the Vulkan logo are registered trademarks of the Khronos Group Inc.

            Debian is a registered trademark of Software in the Public Interest, Inc.
          </Typography>
        </div>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{ justifyContent: 'left', color: 'text.secondary' }}
        >
          <IconButton
            color="inherit"
            size="small"
            href="https://vinkvfx.mailcoach.app/feed/524e4b70-ee4a-434f-8214-013f2640d35a"
            aria-label="RSS"
            sx={{ alignSelf: 'center' }}
          >
            <RssFeed />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.linkedin.com/in/gilles-vink/"
            aria-label="LinkedIn"
            sx={{ alignSelf: 'center' }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
